import type { User } from '@local/login';
import { DateTimeCell } from '@local/web-design-system-2/dist/components/GenericListing/ListCells';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { defaultStringSort, timestampSort } from '@local/workspaces/dist/utils/Sorting';

import {
    OBJECT_HEADER_DATA_TYPE,
    OBJECT_HEADER_LAST_MODIFIED,
    OBJECT_HEADER_MODIFIED_BY,
    OBJECT_HEADER_NAME,
} from 'src/strings';

import { EmptyCell, FileCell, FolderCell } from './fieldCells';
import { SchemaCell } from './SchemaCell';
import { UserCell } from './UserCell';

export const folderRowDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: 'Name',
        component: FolderCell,
    } as FieldDefinition<string>,
    {
        key: 'name2',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'name3',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'name4',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
];

export const objectDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: OBJECT_HEADER_NAME,
        component: FileCell,
        sx: {
            width: '30%',
        },
        // sortFunction: defaultStringSort, // Disabled until we have fix sorting in the API for object name, rather than the whole path (https://seequent.atlassian.net/browse/CENPLAT-25816)
    } as FieldDefinition<string>,
    {
        key: 'schema',
        label: OBJECT_HEADER_DATA_TYPE,
        component: SchemaCell,

        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
    {
        key: 'modified_by',
        label: OBJECT_HEADER_MODIFIED_BY,
        component: UserCell,
        // sortFunction: userSort, // Disabled until we have fix sorting in the API for modified_by user's name, rather than the user's UUID (https://seequent.atlassian.net/browse/CENPLAT-25815)
    } as FieldDefinition<User>,
    {
        key: 'modified_at',
        label: OBJECT_HEADER_LAST_MODIFIED,
        component: DateTimeCell,
        sortFunction: timestampSort,
    } as FieldDefinition<string>,
];

export const recycledObjectDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: OBJECT_HEADER_NAME,
        component: FileCell,
        sx: {
            width: '30%',
        },
        // sortFunction: defaultStringSort, // Disabled until we have fix sorting in the API for object name, rather than the whole path (https://seequent.atlassian.net/browse/CENPLAT-25816)
    } as FieldDefinition<string>,
    {
        key: 'schema',
        label: OBJECT_HEADER_DATA_TYPE,
        component: SchemaCell,
        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
    {
        key: 'created_at',
        label: OBJECT_HEADER_LAST_MODIFIED,
        component: DateTimeCell,
        sortFunction: timestampSort,
    } as FieldDefinition<string>,
];
